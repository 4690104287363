/* eslint-disable complexity */
import {useState} from 'react';
import {Typography, Box} from 'components/mui';
import {useMutation} from 'graphql/client';
import {FileWithPath} from 'components/util/fileUtil';
import {UploadedFileDisplay} from 'components/ui/forms/file-upload/UploadedFileDisplay';
import {DragAndDropArea} from 'components/ui/forms/file-upload/DragAndDropArea.jsx';
import REGISTER_UPLOADED_FILE from 'graphql/components/providers/form-provider/RegisterUploadedFile.graphql';

import {
    uploadCsvFile,
    getNumberOfDataRows,
    getMissingFileHeaders,
    findReplacementsforHeader,
    createFileWithNewHeaders,
} from 'components/util/fileUploadProviderUtils';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {ValidateFileCallbacks} from 'components/providers/CommonUploadContent';
import {FieldData} from './FormStateProvider';

const logger = new Logger('FileUploadProvider');

export interface UploadedFileData {
    name: string;
    url: string;
    size?: number;
}

export interface FileUploadProviderProps {
    children: React.FunctionComponent;
    file?: UploadedFileData;
    maxNumberOfDataRows?: number;
    minNumberOfDataRows?: number;
    onComplete: (fileData: FieldData['value']) => void;
    onError: (errorMessage: string) => void;
    onRemove: () => void;
    requiredHeaders: string[];
    uploadDirective: string;
    border: string;
    dragAndDropFileUploadId?: string;
    convertWebsiteColumnTo?: string;
    hasError?: boolean;
}

function FileUploadProvider({
    file,
    children,
    maxNumberOfDataRows = 100000,
    minNumberOfDataRows = 0,
    onComplete,
    onError,
    onRemove,
    requiredHeaders = [],
    uploadDirective,
    border = 'none',
    dragAndDropFileUploadId,
    convertWebsiteColumnTo = null,
    hasError = false,
}: FileUploadProviderProps) {
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [registerUploadedFile, {loading, data}] = useMutation(
        REGISTER_UPLOADED_FILE,
        {}
    );

    if (loading) {
        return (
            <DragAndDropArea border={border}>
                <Box className="border-area">
                    <Typography>Saving file...</Typography>
                </Box>
            </DragAndDropArea>
        );
    }

    if (data && file) {
        return (
            <UploadedFileDisplay
                border={border}
                fileName={file.name}
                onRemove={onRemove}
            />
        );
    }

    async function uploadFile(selectedFile: FileWithPath): Promise<void> {
        setIsFileUploading(true);

        try {
            const formData = new FormData();
            formData.append(uploadDirective, selectedFile, selectedFile.name);

            const response = await uploadCsvFile(formData);

            const fileData = response.data[0] as unknown as UploadedFileData;

            await registerUploadedFile({
                variables: {
                    url: fileData.url,
                    name: fileData.name,
                    size: selectedFile.size,
                },
                onCompleted: () => {
                    onComplete(fileData);
                },
            });
        } catch (error) {
            logErrorMessages(error, logger);
            setIsFileUploading(false);
            setErrorMessage(error.message);
        }

        setIsFileUploading(false);
    }

    async function validateFile(
        file: FileWithPath,
        {onAccept, onReject}: ValidateFileCallbacks
    ) {
        if (file.type === 'application/json' && file.name.endsWith('.json')) {
            return await onAccept(file);
        }

        const missingHeaders = await getMissingFileHeaders(
            file,
            requiredHeaders,
            rejectFile
        );

        const numberOfDataRows = await getNumberOfDataRows(file, rejectFile);
        const websiteHeaderReplacements = await findReplacementsforHeader(file);

        if (convertWebsiteColumnTo) {
            file = websiteHeaderReplacements
                ? await createFileWithNewHeaders(
                      file,
                      convertWebsiteColumnTo,
                      websiteHeaderReplacements[0],
                      rejectFile
                  )
                : file;
        }

        if (
            missingHeaders.length &&
            !websiteHeaderReplacements &&
            convertWebsiteColumnTo
        ) {
            onReject(
                `Couldn't find the following columns in the CSV you uploaded: website, domain, or url`
            );
        } else if (numberOfDataRows < minNumberOfDataRows) {
            onReject(
                `The file you uploaded does not meet the minimum of ${minNumberOfDataRows} required rows `
            );
        } else if (numberOfDataRows > maxNumberOfDataRows) {
            onReject(
                `The file you uploaded exceeds the maximum of ${maxNumberOfDataRows} rows`
            );
        } else {
            await onAccept(file);
        }
    }

    function rejectFile(errorMessage: string): void {
        onError(errorMessage);
        setErrorMessage(errorMessage);
    }

    async function onFileUpload(file: FileWithPath): Promise<void> {
        setErrorMessage(null);
        await validateFile(file, {
            onAccept: uploadFile,
            onReject: rejectFile,
        });
    }

    return children({
        onFileUpload,
        validateFile,
        isFileUploading,
        errorMessage,
        dragAndDropFileUploadId,
        border,
        hasError,
    });
}

export {FileUploadProvider};
