import styled from 'styled-components';
import {Box} from 'components/mui';

const DragAndDropArea = styled(Box)`
    width: 100%;
    min-width: 160px;
    margin: 0 auto;
    cursor: pointer;
    padding: 0;

    &.container {
        padding: 0;
    }

    .border-area {
        display: flex;
        flex-flow: column nowrap;
        gap: ${({theme}) => theme.spacing(2)};
        background-color: ${({theme}) => theme.palette.background.default};
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        min-height: 158px;
        padding: 10px;

        img {
            display: none;
        }

        @media (min-width: ${(props) =>
                props.theme.mobileToDesktopBreakpoint}px) {
            min-height: 230px;
            img {
                display: block;
            }
        }
    }

    @media (min-width: ${(props) => props.theme.mobileToDesktopBreakpoint}px) {
        padding: 0;

        img {
            display: block;
        }
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${({theme}) => theme.palette.secondary.dark};
    }
`;

export {DragAndDropArea};
