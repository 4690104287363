import Image from 'next/legacy/image';
import {Box, Typography} from 'components/mui';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {DragAndDropArea} from './DragAndDropArea';

function DragAndDropFileUpload({onDropAccepted, accept, maxFiles, id, border}) {
    const {getRootProps, getInputProps} = useDropzone({
        accept,
        maxFiles,
        onDropAccepted: async (files) => {
            await onDropAccepted(files[0]);
        },
    });

    return (
        <DragAndDropArea className="container" border={border}>
            <Box {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} data-testid={id} />
                <Box className="border-area">
                    <Image
                        src={
                            '/components/forms/file-upload/upload-secondary-icon.svg'
                        }
                        height="44"
                        width="70"
                        alt="upload"
                    />
                    <Box className="text-wrapper">
                        <Typography variant="subtitle1">
                            Click to <strong>Upload</strong> or{' '}
                            <strong>Drag</strong> & <strong>Drop</strong> a File
                        </Typography>
                        <Typography variant="body2">
                            Max file size: 100MB
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </DragAndDropArea>
    );
}

DragAndDropFileUpload.propTypes = {
    id: PropTypes.string,
    onDropAccepted: PropTypes.func.isRequired,
    accept: PropTypes.array,
    maxFiles: PropTypes.number,
    border: PropTypes.string,
};

DragAndDropFileUpload.defaultProps = {
    accept: ['.csv', '.json'],
    maxFiles: 1,
    border: 'none',
};

export {DragAndDropFileUpload};
