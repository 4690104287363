import _ from 'lodash';
import {UploadedFileData} from 'components/providers/FileUploadProvider';
import axios from 'axios';
import {
    FileWithPath,
    extractDataRows,
    extractHeaders,
    replaceHeaders,
} from 'components/util/fileUtil';
import {removeEmptySpacesHyphensAndUnderscores} from 'utils/strings';

const SUPPORTED_NAMES_FOR_WEBSITE = [
    'site',
    'website',
    'domain',
    'url',
    'uri',
    'webaddress',
    'webpage',
    'page',
    'internetsite',
    'internetdomain',
    'internetaddress',
    'location',
    'homepage',
    'registereddomain',
];
interface FileUploadResponse {
    data: UploadedFileData[];
}

interface UploadedFileDataResponse {
    data: UploadedFileData[];
}
const uploadCsvFile = async (formData): Promise<UploadedFileDataResponse> => {
    const response: FileUploadResponse = await axios.post(
        '/api/file-upload',
        formData,
        {
            headers: {'Content-Type': 'multipart/form-data'},
        }
    );
    return response;
};

const getNumberOfDataRows = async (
    file: FileWithPath,
    onError: (errorMessage) => void
): Promise<number> => {
    try {
        const dataRows = await extractDataRows(file, 1);
        return dataRows.length;
    } catch (error) {
        onError(error);
        throw error;
    }
};

const getMissingFileHeaders = async (
    file: FileWithPath,
    requiredHeaders: string[],
    onError: (errorMessage: string) => void
): Promise<string[]> => {
    try {
        const fileHeaders = await extractHeaders(file);
        const formattedFileHeaders = fileHeaders.map((header) =>
            header.toLowerCase().trim()
        );
        const formattedRequiredHeaders = requiredHeaders.map((header) =>
            header.toLowerCase().trim()
        );

        return _.difference(formattedRequiredHeaders, formattedFileHeaders);
    } catch (error) {
        onError(error);
        throw error;
    }
};

const findReplacementsforHeader = async (
    file: FileWithPath
): Promise<string[]> => {
    const fileHeaders = await extractHeaders(file);
    const replacements = _.intersection(
        fileHeaders.map((header) =>
            removeEmptySpacesHyphensAndUnderscores(header)
        ),
        SUPPORTED_NAMES_FOR_WEBSITE
    );

    if (replacements.length === 0) {
        return null;
    }
    return replacements;
};

const createFileWithNewHeaders = async (
    file: FileWithPath,
    newHeader: string,
    oldHeader: string,
    onError: (errorMessage: string) => void
): Promise<FileWithPath> => {
    try {
        const newFile = await replaceHeaders(file, newHeader, oldHeader);
        return newFile;
    } catch (error) {
        onError(error);
        throw error;
    }
};

const createMockFileWithPath = (fileData: string[][]): FileWithPath => {
    const fileName = 'mockFile.csv';
    const filePath = '/Users/Joel/Documents/';
    const fileType = 'text/csv;charset=utf-8';
    const blob = new Blob([fileData.map((row) => row.join(',')).join('\n')], {
        type: fileType,
    });

    const file: FileWithPath = new File([blob], fileName, {
        type: fileType,
    }) as FileWithPath;
    file.path = filePath;

    return file;
};

export {
    uploadCsvFile,
    getNumberOfDataRows,
    getMissingFileHeaders,
    findReplacementsforHeader,
    createFileWithNewHeaders,
    createMockFileWithPath,
};
